import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAllUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getAllUsers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getUserById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getUserById/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    userCheck(ctx, { email }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`userCheck/${email}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveUser', userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getJobNumbers(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('getJobNumbers', userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    saveRowData(ctx, rowData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveRowData', rowData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteRowData(ctx, rowData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('deleteRowData', rowData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    


    

    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateUser/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    updatePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`user/updatePassword/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
  },
};
